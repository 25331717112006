import { Alert, Badge, Spin, Modal } from "antd";
// import Modal from "antd/lib/modal/Modal";
import { Auth } from "aws-amplify";
import { useMoralisDapp } from "contexts/MoralisDappProvider/MoralisDappProvider";
import { getNativeByChain } from "helpers/networks";
import { useNFTTokenIds } from "hooks/useNFTTokenIds";
import React, { useState, useEffect } from "react";
import {
  useMoralis,
  useMoralisQuery,
  useWeb3ExecuteFunction,
} from "react-moralis";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import NFTItem from "./NFTItem";

function NFTList({ limit, isLoggedIn, address, chain }) {
  const history = useHistory();
  const { nftAddress } = useMoralisDapp();
  const { NFTTokenIds, totalNFTs, fetchSuccess } = useNFTTokenIds(
    chain, address
  );
  const [visible, setVisibility] = useState(false);
  const [nftToBuy, setNftToBuy] = useState(null);
  const [loading, setLoading] = useState(false);
  const contractProcessor = useWeb3ExecuteFunction();
  const { chainId, marketAddress, contractABI, walletAddress } =
    useMoralisDapp();
  const nativeName = getNativeByChain(chainId);
  const contractABIJson = JSON.parse(contractABI);
  const { Moralis, isWeb3Enabled } = useMoralis();
  const queryMarketItems = useMoralisQuery("CreatedMarketItems", (query) =>
    query.limit(1000)
  );
  const fetchMarketItems = JSON.parse(
    JSON.stringify(queryMarketItems.data, [
      "objectId",
      "createdAt",
      "price",
      "nftContract",
      "itemId",
      "sold",
      "tokenId",
      "seller",
      "owner",
      "confirmed",
      "tokenId_decimal"
    ])
  );
  const purchaseItemFunction = "createMarketSaleERC20";

  async function purchase() {
    setLoading(true);
    const tokenDetails = getMarketItem(nftToBuy);
    const itemID = tokenDetails.itemId;
    const tokenPrice = tokenDetails.price;
    const ops = {
      contractAddress: marketAddress,
      functionName: purchaseItemFunction,
      abi: contractABIJson,
      params: {
        nftContract: nftToBuy.token_address,
        itemId: itemID,
        token: "0xbd5E96a0212f5831f35853765c73c2B84f8E9B70",
      },
      // msgValue: tokenPrice,
    };

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        setLoading(false);
        setVisibility(false);
        updateSoldMarketItem();
        succPurchase();
      },
      onError: (error) => {
        setLoading(false);
        failPurchase();
      },
    });
  }

  const handleBuyClick = (nft) => {
    console.log("CLicked");
    setNftToBuy(nft);
    setVisibility(true);
  };

  function succPurchase() {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: "Success!",
      content: `You have purchased this NFT`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function failPurchase() {
    let secondsToGo = 5;
    const modal = Modal.error({
      title: "Error!",
      content: `There was a problem when purchasing this NFT`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  async function updateSoldMarketItem() {
    const id = getMarketItem(nftToBuy).objectId;
    const marketList = Moralis.Object.extend("CreatedMarketItems");
    const query = new Moralis.Query(marketList);
    await query.get(id).then((obj) => {
      obj.set("sold", true);
      obj.set("owner", walletAddress);
      obj.save();
    });
  }

  const getMarketItem = (nft) => {
    const result = fetchMarketItems?.find(
      (e) =>
        e.nftContract === nft?.token_address &&
        (e.tokenId === nft?.token_id || e.tokenId_decimal == nft?.token_id) &&
        e.sold === false &&
        e.confirmed === true
    );
    return result;
  };
  const onClose = (e) => {
    console.log(e, "I was closed.");
  };

  const [loggedIn, setLoggedIn] = useState(false);

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // console.log(user);
      setLoggedIn(true);
    } catch (e) {
      console.log('error: ', e)
    }
  }

  function notSignedIn() {
    const modal = Modal.error({
      title: "Not Signed In!",
      content: "Please sign in or create a new account to view our NFT collections.",
    });
  }

  function incorrectChain() {
    const modal = Modal.error({
      title: "Please select the correct chain!",
      content: "Your metamask is currently set on the incorrect chain, please make sure you are connected to the polygon testnet and refresh this page to view this collection!",
    });
  }

  function noMetaMask() {
    const modal = Modal.error({
      title: "No Metamask!",
      content: `Please make sure you have metamask installed and enabled. If you need help with metamask, please go to the home page and watch the tutorial video.`,
    });
  }

  useEffect(() => {
    checkUser();
  }, [])

  if (!NFTTokenIds) {
    return <h1>NO NFTS TO DISPLAY</h1>;
  }

  return (
    <div className="nft-list">
      {NFTTokenIds.slice(0, limit).map((nft, index) => {
        return (
          <>
            <NFTItem
              key={index}
              index={index}
              nft={nft}
              getMarketItem={getMarketItem}
              handleBuyClick={handleBuyClick}
              chain={chain}
              connectedChain={chainId}
            />
          </>
        );
      })}
      {limit && (

        <div>
          <a
            className="joinBtn viewCollectionBtn"
            // href={`/${NFTTokenIds[0]?.name}`}
            onClick={() => {
              console.log(isLoggedIn)
              if (isWeb3Enabled) {
                if (!isLoggedIn) {
                  notSignedIn();
                } else if (chainId !== "0x13881") {
                  incorrectChain();
                } else {
                  history.push(`/${NFTTokenIds[0]?.name}`);
                }
              } else {
                noMetaMask();
              }
            }}
            target="_blank"
            rel="noreferrer"
          >
            View Collection
          </a>
        </div>

      )}
      {getMarketItem(nftToBuy) ? (
        <Modal
          title={`Buy ${nftToBuy?.name} #${nftToBuy?.token_id}`}
          visible={visible}
          onCancel={() => setVisibility(false)}
          onOk={() => purchase()}
          okText="Buy"
        >
          <Spin spinning={loading}>
            <div
              style={{
                width: "250px",
                margin: "auto",
              }}
            >
              <Badge.Ribbon
                color="green"
                text={`${getMarketItem(nftToBuy).price} IEDU`}
              >
                <img
                  src={nftToBuy?.image}
                  style={{
                    width: "250px",
                    borderRadius: "10px",
                    marginBottom: "15px",
                  }}
                />
              </Badge.Ribbon>
            </div>
          </Spin>
        </Modal>
      ) : (
        <Modal
          title={`Buy ${nftToBuy?.name} #${nftToBuy?.token_id}`}
          visible={visible}
          onCancel={() => setVisibility(false)}
          onOk={() => setVisibility(false)}
        >
          <img
            src={nftToBuy?.image}
            style={{
              width: "250px",
              margin: "auto",
              borderRadius: "10px",
              marginBottom: "15px",
            }}
          />

          <Alert message="This NFT is currently not for sale" type="warning" />
        </Modal>
      )}
    </div>
  );
}

export default NFTList;
