//for connecting to the blockchain
const Web3 = require('web3');
const abi = require('./abi.json');
// const fetch = require("node-fetch");
const NODE_URL = "https://speedy-nodes-nyc.moralis.io/67a0ca1ebd6b99335d88a2ce/polygon/mumbai";
const provider = new Web3.providers.HttpProvider(NODE_URL);
var web3 = new Web3(provider);
var contract = new web3.eth.Contract(abi, "0x573Cb7d5064798B05A4dF809Bea630101A48eE5b");

let numOfItems;
let jsonURL;
let all_attributes = [];
let promises = [];
let temp_array = [[],[]];
const getData = async () => {
    
    await contract.methods.totalSupply().call(function (err, res) {
        if (err) {
            console.log(err);
        };
        console.log("num", res)
        numOfItems = res;
    });

    console.log("Retrieving data from block chain, may take a minute.")
    for (var i = 0; i < numOfItems; i++) {
        console.log(i / numOfItems * 100 + "% done");
        await contract.methods.tokenURI(i).call(function (err, res) {
            if (err) {
                console.log(err);
                numOfItems++;
            };
            jsonURL = res;
        }).catch(error => {
            console.log("");
        });
        await fetch(jsonURL).then(res => res.json()).then(out => {
            temp_array[0].push(out.attributes)
            temp_array[1].push(out.name)
        }).catch(error => {console.log("e")});
    }

    console.log("100% done\n");
    return temp_array;
}

export default getData;