import React, { useState } from "react";
import { useMoralis } from "react-moralis";
import {
  Card,
  Image,
  Tooltip,
  Modal,
  Input,
  Alert,
  Spin,
  Button,
  notification,
} from "antd";
import { getNativeByChain } from "helpers/networks";
import { getCollectionsByChain } from "helpers/collections";
import { useMoralisQuery } from "react-moralis";
import { FileSearchOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { useMoralisDapp } from "contexts/MoralisDappProvider/MoralisDappProvider";
import { getExplorer } from "helpers/networks";
import { useWeb3ExecuteFunction } from "react-moralis";
import { Container } from "react-bootstrap";
import HelmetMetaData from "components/HelmetMetaData";
import { useHistory, useParams } from "react-router-dom";
import NFTList from "components/NFT/NFTList";
import { useNFTTokenIds } from "hooks/useNFTTokenIds";
import { networkCollections } from "../../constants/collections";
const { Meta } = Card;

const styles = {
  NFTs: {
    display: "flex",
    flexWrap: "wrap",
    WebkitBoxPack: "start",
    justifyContent: "center",
    margin: "0 auto",
    maxWidth: "1000px",
    gap: "10px",
  },
  banner: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: "0 auto",
    width: "600px",
    //borderRadius: "10px",
    height: "150px",
    marginBottom: "50px",
    paddingBottom: "60px",
    borderBottom: "solid 1px #e3e3e3",
    flexDirection: "column",
  },
  logo: {
    height: "115px",
    width: "115px",
    borderRadius: "50%",
    // positon: "relative",
    // marginTop: "-80px",
    border: "solid 4px white",
  },
};

function NFTPage(props) {
  const params = useParams();
  const { chainId, marketAddress, nftAddress } = useMoralisDapp();
  const { NFTTokenIds, totalNFTs, fetchSuccess } = useNFTTokenIds(
    chainId, nftAddress
  );
  // console.log({ params });

  return (
    <Container>
      <HelmetMetaData
        title="Marketplace - Collection | Innov-Edu"
        description="Check on the Innov-Edu NFTs that you own on this page."
      ></HelmetMetaData>
      <div className="explore-banner">
        {/* {console.log(nftAddress)} */}
        <Image
          preview={false}
          src={NFTTokenIds[0]?.image || "error"}
          alt=""
          style={styles.logo}
        />
        <div style={styles.text}>
          <>
            <div>{`${NFTTokenIds[0]?.name}`}</div>
            <div
              style={{
                fontSize: "15px",
                color: "#9c9c9c",
                fontWeight: "normal",
              }}
            >
              Collection Size: {`${totalNFTs}`}
            </div>
          </>
        </div>
        <p>
          The Hangman collection includes practice (not real) NFTs that we are
          providing for free to our community so everyone can practice their NFT
          acquisition, exchange and trading skills risk free.
        </p>
      </div>
      <NFTList address={props.address} isLoggedIn={props.isLoggedIn} chain={props.chain} />
    </Container>
  );
}

export default NFTPage;
