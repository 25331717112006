const AWS = require('aws-sdk')
require('dotenv').config()

// TODO: move to .env. When I try to use .env, it gives "missing credentials in config, if using AWS_CONFIG_FILE error"
const SES_CONFIG = {
  apiVersion: '2010-12-01',
  accessKeyId: 'AKIAQRTKFLENSD4X5IF6',
  // accessKeyId: process.env.AWS_SES_ACCESS_KEY_ID,
  secretAccessKey: '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',
  // secretAccessKey: process.env.AWS_SES_SECRET_ACCESS_KEY,
  region: 'us-east-2'
  // region: process.env.AWS_SES_REGION
}

const AWS_SES = new AWS.SES(SES_CONFIG)

// Sends default subcription email to the email address input from form
// Takes in recipient email and language as parameters, so that the user can receive
// the email in english or french.
module.exports.sendEmail = (recipientEmail, lang) => {
  let params = {
    Source: 'info@innovfin.ca',
    Destination: {
      ToAddresses: [recipientEmail]
    },
    ReplyToAddresses: ['info@innovfin.ca'],
    Message: {
      Body: {
        Html: {
          Charset: 'UTF-8',
          Data:
            lang === 'fr'
              ? 'Veuillez finaliser votre abonnement en utilisant le lien ci-dessous ! <br/> <a href="http://eepurl.com/htGfVj">Confirmer l\'abonnement</a><br/>Merci de votre abonnement!'
              :
              'Please finalize your subscription using the link below! <br/> <a href="http://eepurl.com/htGfVj">Confirm Subscription</a><br/>Thank you for subscribing!'
        }
      },
      Subject: {
        Charset: 'UTF-8',
        Data:
          lang === 'fr'
            ? 'InnovFin - Veuillez confirmer votre inscription' :
            'InnovFin - Please Confirm Your Subscription'
      }
    }
  }
  return AWS_SES.sendEmail(params).promise()
}

// Sends an email from the user to info@innovfin.ca about their inquiry.
// Takes in data from formik form and destructures the user's name, email, inquiry(inputText), and
// their phone number if it is provided.
module.exports.sendMintRequestEmail = (nftId) => {
  let params = {
    Source: "info@innovfin.ca",
    Destination: {
      ToAddresses: ['info@innovfin.ca'],
			CcAddresses: ['a.tao@innovfin.ca']
    },
    ReplyToAddresses: ['info@innovfin.ca'],
    Message: {
      Body: {
        Html: {
          Charset: 'UTF-8',
          Data: `A user has submitted a request to mint NFT #${nftId} after combining two NFTs` +
          `<br/>You can mint the NFT and look at more details at <a href="https://www.marketplace.innov-edu.ca/Transactions">https://www.marketplace.innov-edu.ca/Transactions</a> as long as you have an admin wallet connected.`
        }
      },
      Subject: {
        Charset: 'UTF-8',
        Data: `MARKETPLACE: User request - Mint NFT #${nftId}`
      }
    }
  }
  return AWS_SES.sendEmail(params).promise()
}
