import { useEffect } from "react";
import useChain from "hooks/useChain";
// import { useMoralisDapp } from "contexts/MoralisDappProvider/MoralisDappProvider";
// import { Menu } from "antd";
import { PolygonLogo } from "./Logos";

// const menuItems = [
//   // {
//   //   key: "0x1",
//   //   value: "Ethereum",
//   //   icon: <ETHLogo />,
//   // },
//   // {
//   //   key: "0x539",
//   //   value: "Local Chain",
//   //   icon: <ETHLogo />,
//   // },
//   // {
//   //   key: "0x3",
//   //   value: "Ropsten Testnet",
//   //   icon: <ETHLogo />,
//   // },
//   // {
//   //   key: "0x4",
//   //   value: "Rinkeby Testnet",
//   //   icon: <ETHLogo />,
//   // },
//   // {
//   //   key: "0x2a",
//   //   value: "Kovan Testnet",
//   //   icon: <ETHLogo />,
//   // },
//   // {
//   //   key: "0x5",
//   //   value: "Goerli Testnet",
//   //   icon: <ETHLogo />,
//   // },
//   // {
//   //   key: "0x38",
//   //   value: "Binance",
//   //   icon: <BSCLogo />,
//   // },
//   // {
//   //   key: "0x61",
//   //   value: "Smart Chain Testnet",
//   //   icon: <BSCLogo />,
//   // },
//   // {
//   //   key: "0x89",
//   //   value: "Polygon",
//   //   icon: <PolygonLogo />,
//   // },
//   {
//     key: "0x13881",
//     value: "Mumbai",
//     icon: <PolygonLogo />,
//   },
//   // {
//   //   key: "0xa86a",
//   //   value: "Avalanche",
//   //   icon: <AvaxLogo />,
//   // },
// ];

function Chains() {
  const { switchNetwork } = useChain();
  // const { chainId } = useMoralisDapp();

  // const [selected, setSelected] = useState({});
  useEffect(() => {
    switchNetwork("0x13881");
  }, []);

  // useEffect(() => {
  //   if (!chainId) return null;
  //   const newSelected = menuItems.find((item) => item.key === chainId);
  //   setSelected(newSelected);
  //   console.log("current chainId: ", chainId);
  // }, [chainId]);

  // const handleMenuClick = (e) => {
  //   console.log("switch to: ", e.key);
  //   switchNetwork(e.key);
  // };

  // const menu = (
  //   <Menu onClick={handleMenuClick}>
  //     {menuItems.map((item) => (
  //       <Menu.Item key={item.key} icon={item.icon} style={styles.item}>
  //         <span style={{ marginLeft: "5px" }}>{item.value}</span>
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );

  return <div></div>;
}

export default Chains;
