import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { MoralisProvider } from "react-moralis";

import { MoralisDappProvider } from "./contexts";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./style.css";

import { HelmetProvider } from "react-helmet-async";

import Amplify, { Auth } from 'aws-amplify';
import config from './aws-exports';
import Web3 from "web3";

Auth.configure(config);

// Moralis APP ID and ServerURL

var web3 = new Web3(Web3.givenProvider);

// function to wait and get chainId
const get = async (action) => {
  try {
    action(await web3.eth.getChainId())
  } catch (e) {
    // if the provider is not set, meaning that the user does not have metamask, set a default provider just so they can see the page
    if(e.toString().includes("Provider not set")){
      action(137);
    }
  }
}

const Application = () => {
  const [APP_ID, setAPP_ID] = useState(process.env.REACT_APP_MORALIS_APPLICATION_ID);
  const [SERVER_URL, setSERVER_URL] = useState(process.env.REACT_APP_MORALIS_SERVER_URL);
  const [chainId, setChainId] = useState();

  const isServerInfo = APP_ID && SERVER_URL ? true : false;
  get(setChainId).then(e => {
    if (!chainId) {
      return (
        <>
          <MoralisProvider appId={process.env.REACT_APP_MORALIS_APPLICATION_ID_LIVE} serverUrl={process.env.REACT_APP_MORALIS_SERVER_URL_LIVE}>
            {/* {console.log("in live env")} */}
            <MoralisDappProvider>
              <App isServerInfo />
            </MoralisDappProvider>
          </MoralisProvider>
        </>
      )
    }
  });
  if (isServerInfo) {
    return (
      <>
        {chainId && (
          chainId == 137 ? (<MoralisProvider appId={process.env.REACT_APP_MORALIS_APPLICATION_ID_LIVE} serverUrl={process.env.REACT_APP_MORALIS_SERVER_URL_LIVE}>
            {/* {console.log("in live env")} */}
            <MoralisDappProvider>
              <App isServerInfo />
            </MoralisDappProvider>
          </MoralisProvider>) :
            (
              <MoralisProvider appId={process.env.REACT_APP_MORALIS_APPLICATION_ID} serverUrl={process.env.REACT_APP_MORALIS_SERVER_URL}>
                {console.log("in test env")}
                <MoralisDappProvider>
                  {/* {console.log(chainId == 80001)} */}
                  <App isServerInfo />
                </MoralisDappProvider>
              </MoralisProvider>)
        )
        }
      </>
    );
  }
  // else if (isServerInfo && chainId !== 137) {
  //   console.log("in prod env")
  //   return (
  //     <MoralisProvider appId={process.env.REACT_APP_MORALIS_APPLICATION_ID_LIVE} serverUrl={process.env.REACT_APP_MORALIS_SERVER_URL_LIVE}>
  //       <MoralisDappProvider>
  //         <App isServerInfo />
  //       </MoralisDappProvider>
  //     </MoralisProvider>
  //   )
  else {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <p>No Moralis dapp set : Check ENV</p>
        </div>
      </div>
    );
  }
};


const helmetContext = {};

ReactDOM.render(
  // <React.StrictMode>
  <HelmetProvider context={helmetContext}>
    <Application />
  </HelmetProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
