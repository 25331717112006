import React, { useState } from "react";
import { useMoralis, useMoralisQuery, useWeb3ExecuteFunction } from "react-moralis";
import { useMoralisDapp } from "../../contexts/MoralisDappProvider/MoralisDappProvider";
import { Table, Tag, Space, Spin, Modal } from "antd";
import { PolygonCurrency } from "../../components/Chains/Logos";
import moment from "moment";
import HelmetMetaData from "components/HelmetMetaData";
import { Button } from "react-bootstrap";

const styles = {
  table: {
    margin: "0 auto",
    width: "1000px",
  },
  mintBtn: {
    margin: "1em 0",
    fontSize: "12px",
    padding: "0em 0.5em",
    backgroundColor: "white",
    borderColor: "grey",
    color: "black",
    width: "50%"
  },
};

function NFTMarketTransactions() {
  const contractProcessor = useWeb3ExecuteFunction();
  const { walletAddress, nftAddress,  } = useMoralisDapp();
  const { Moralis } = useMoralis();
  const queryItemImages = useMoralisQuery("CreatedMarketImages");
  const fetchItemImages = JSON.parse(
    JSON.stringify(queryItemImages.data, [
      "nftContract",
      "tokenId",
      "name",
      "image",
    ])
  );
  const queryMarketItems = useMoralisQuery("CreatedMarketItems");
  const fetchMarketItems = JSON.parse(
    JSON.stringify(queryMarketItems.data, [
      "updatedAt",
      "price",
      "nftContract",
      "itemId",
      "sold",
      "tokenId",
      "seller",
      "owner",
    ])
  )
    .filter(
      (item) => item.seller === walletAddress || item.owner === walletAddress
    )
    .sort((a, b) =>
      a.updatedAt < b.updatedAt ? 1 : b.updatedAt < a.updatedAt ? -1 : 0
    );

  const queryMintItems = useMoralisQuery("CreatedMintRequest");
  const fetchMintItems = JSON.parse(
    JSON.stringify(queryMintItems.data, [
      "updatedAt",
      "minted",
      "creator",
      "nftContract",
      "itemId",
      "ipfsURI",
      "objectId"
    ])
  )

  const admins = ['0x8fa61fed6ae280c460724a3c6cfdc1a7eb86adb5', '0xF326618bAa711C74e04c961201F3c2db037b5eA4'];
  // formats admins
  const adminsFormatted = admins.map(e => e.toLowerCase());
  const [spinning, setSpinning] = useState(false);

  function getImage(addrs, id) {
    const img = fetchItemImages.find(
      (element) => element.nftContract === addrs && element.tokenId === id
    );
    return img?.image;
  }

  function getName(addrs, id) {
    const nme = fetchItemImages.find(
      (element) => element.nftContract === addrs && element.tokenId === id
    );
    return nme?.name;
  }

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Item",
      key: "item",
      render: (text, record) => (
        <Space size="middle">
          <img
            src={getImage(record.collection, record.item)}
            style={{ width: "40px", borderRadius: "4px" }}
          />
          <span>#{record.item}</span>
        </Space>
      ),
    },
    {
      title: "Collection",
      key: "collection",
      render: (text, record) => (
        <Space size="middle">
          <span>{getName(record.collection, record.item)}</span>
        </Space>
      ),
    },
    {
      title: "Transaction Status",
      key: "tags",
      dataIndex: "tags",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = "geekblue";
            let status = "BUY";
            if (tag === false) {
              color = "volcano";
              status = "waiting";
            } else if (tag === true) {
              color = "green";
              status = "confirmed";
            }
            if (tag === walletAddress) {
              status = "SELL";
            }
            return (
              <Tag color={color} key={tag}>
                {status.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      render: (e) => (
        <Space size="middle">
          <img src="./ieduToken.png" />
          <span>{e}</span>
        </Space>
      ),
    },
  ];

  const data = fetchMarketItems?.map((item, index) => ({
    key: index,
    date: moment(item.updatedAt).format("DD-MM-YYYY HH:mm"),
    collection: item.nftContract,
    item: item.tokenId,
    tags: [item.seller, item.sold],
    price: item.price,
  }));

  const mintTableColumns = [
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   key: "date",
    // },
    {
      title: "IPFS Location",
      key: "ipfs",
      render: (text, record) => (
        <Space size="middle">
          {/* <img
            src={getImage(record.collection, record.item)}
            style={{ width: "40px", borderRadius: "4px" }}
          /> */}
          <span>{record.ipfs}</span>
        </Space>
      ),
    },
    {
      title: "Requested By",
      key: "creator",
      render: (text, record) => (
        <Space size="middle">
          <span>{record.creator}</span>
        </Space>
      ),
    },
    {
      title: "Mint Status",
      key: "minted",
      dataIndex: "minted",
      render: (tags, record) => (
        <>
          {tags.map((tag) => {
            let color = "geekblue";
            let status = "BUY";
            if (tag === false) {
              color = "volcano";
              status = "to be minted";
            } else if (tag === true) {
              color = "green";
              status = "minted";
            }
            if (tag === walletAddress) {
              status = "SELL";
            }
            return (
              <div>
                <Tag color={color} key={record.objectId}>
                  {status.toUpperCase()}
                </Tag>
                {/* {console.log("MetaCID ",record.ipfs.replace(/\/metadata\/[0-9]+.json/g, ""), "\nID ", record.ipfs.replace(/.json/g, "").replace(/\/metadata/g, "").replace(/^.+\//,""))} */}
                {tag === false && <Button style={styles.mintBtn} onClick={() => mint(record.creator, record.ipfs, record.objectId)}>Mint</Button>}
              </div>
            );
          })}
        </>
      ),
    }
  ];

  const mintRequestData = fetchMintItems?.map((item, index) => ({
    key: "requests" + index,
    date: moment(item.updatedAt).format("DD-MM-YYYY"),
    creator: item.creator,
    ipfs: item.ipfsURI,
    // ipfs: item.ipfsURI.replace(/\/metadata\/[0-9]+.json/g, ""),
    minted: [item.minted],
    objectId: item.objectId
    // price: item.price,
  }));

  async function mint(_creator, _metaCID, _id) {
    // console.log(_metaCID)
    setSpinning(true);
    const ops = {
      contractAddress: nftAddress,
      functionName: "createToken",
      abi: [{
        "inputs": [
          {
            "internalType": "address",
            "name": "_to",
            "type": "address"
          },
          {
            "internalType": "string",
            "name": "metadataCID",
            "type": "string"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "createToken",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      }],
      params: {
        _to: _creator,
        metadataCID: _metaCID.replace(/\/metadata\/[0-9]+.json/g, ""),
        tokenId: _metaCID.replace(/.json/g, "").replace(/\/metadata/g, "").replace(/^.+\//, ""),
      },
    };

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        console.log("success");
        updateMintedItem(_id);
        mintedModal();
      },
      onError: (error) => {
        console.log(error);
        alert("an error has occured");
        setSpinning(false);
      },
    });
  }

  function mintedModal() {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: "Minted!",
      content: `The NFT has been successfully minted, it may take a second for the status to update, feel free to navigate the site.`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  async function updateMintedItem(_id) {
    const mintList = Moralis.Object.extend("CreatedMintRequest");
    const query = new Moralis.Query(mintList);
    await query.get(_id).then((obj) => {
      obj.set("minted", true);
      obj.save();
      setSpinning(false);
    });
  }


  return (
    <>
      <HelmetMetaData
        title="Marketplace - Transactions | Innov-Edu"
        description="Take a look at your previous transactions on this page."
      ></HelmetMetaData>
      <div>
        <div style={styles.table}>
          <Table columns={columns} dataSource={data} />
        </div>
        {
          adminsFormatted.indexOf(walletAddress?.toLowerCase()) !== -1 ?
            <Spin spinning={spinning} tip="Your transaction is processing, please wait for it to fully go through.">
              <div style={styles.table}>
                <h1>Admin - Mint</h1>
                <Table columns={mintTableColumns} dataSource={mintRequestData} />
              </div>
            </Spin>
            : null
        }

      </div>
    </>
  );
}

export default NFTMarketTransactions;
const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Item",
    key: "item",
  },
  {
    title: "Collection",
    key: "collection",
  },
  {
    title: "Transaction Status",
    key: "tags",
    dataIndex: "tags",
  },
  {
    title: "Price",
    key: "price",
    dataIndex: "price",
  },
];
