//for generating images
import getData from "./BlockchainData.js";
import jimp from "jimp";
import gifwrap from "gifwrap";
import { useMoralisFile } from "react-moralis";
const fs = require("fs");
// const myArgs = process.argv.slice(2);
const { createCanvas, loadImage } = require("canvas");
// const jimp = require("jimp");
// const gifwrap = require("gifwrap");
const axios = require("axios");
// const getData = require("./BlockchainData.js");
const NAMES_LIST = require("./names.json");

const canvas = createCanvas(1000, 1000);
const ctx = canvas.getContext("2d");
// const { layers, width, height, framesPerGif } = require("./config.js");
const framesPerGif = 5;
var metadata;
var attributes = [];
var animation = false;
var hash = [];
var decodedHash = [];

//for connecting to the blockchain
const Web3 = require('web3');
const abi = require('./abi.json');
// const fetch = require("node-fetch");
// const NODE_URL = "https://speedy-nodes-nyc.moralis.io/67a0ca1ebd6b99335d88a2ce/polygon/mumbai";
const NODE_URL = "https://speedy-nodes-nyc.moralis.io/f473502a809acbf12a7fee98/polygon/mumbai";
const provider = new Web3.providers.HttpProvider(NODE_URL);
var web3 = new Web3(provider);
var contract = new web3.eth.Contract(abi, "0x573Cb7d5064798B05A4dF809Bea630101A48eE5b");

var parent1_ID;
var parent2_ID;
const addMetaData = (_edition, _imageLoc) => {
    let dateTime = Date.now();
    let tempMetadata = {
        imageUrl: _imageLoc,
        tokenId: _edition,
        name: name,
        parent1_id: parent1_ID,
        parent2_id: parent2_ID,
        attributes: attributes,
    };
    metadata = tempMetadata;
    attributes = [];
    hash = [];
    decodedHash = [];
};

const addAttributes = (_element, _edition) => {
    let tempAttr = {
        trait_type: _element.trait_type,
        name: _element.value,
        fileName: _element.fileName
    };
    attributes.push(tempAttr);
};

//function for combining two images
var jsonData;
var jsonData2;
let jsonURL1;
let jsonURL2;
let combineArray;
var blockchain_data = [];
const combine = async (_tokenID1, _tokenID2, _tokenId, _setMetaData, _setImg) => {
    parent1_ID = _tokenID1;
    parent2_ID = _tokenID2;
    await contract.methods.tokenURI(_tokenID1).call(function (err, res) {
        if (err) {
            console.log("");
        };
        jsonURL1 = res;
    });
    await contract.methods.tokenURI(_tokenID2).call(function (err, res) {
        if (err) {
            console.log("");
        };
        jsonURL2 = res;
    });
    await fetch(jsonURL1).then(res => res.json()).then(out => {
        jsonData = out;
    });
    await fetch(jsonURL2).then(res => res.json()).then(out => {
        jsonData2 = out;
    });
    console.log('Parent 1 attributes: ', jsonData.attributes);
    console.log('Parent 2 attributes: ', jsonData2.attributes);
    combineArray = [];
    for (var i = 0; i < jsonData.attributes.length; i++) {
        if ((Math.floor(Math.random() * 2)) == 0) {
            combineArray.push(jsonData.attributes[i])
        } else {
            combineArray.push(jsonData2.attributes[i])
        }
    }
    console.log("Combied attribute array ", combineArray);
    if (JSON.stringify(blockchain_data[0]).includes(JSON.stringify(combineArray))) {
        combine(_tokenID1, _tokenID2, _tokenId, _setImg);
    } else {
        console.log("This NFT is unique, proceeding...")
        createGif(combineArray, _tokenId,_setMetaData, _setImg);
    }

}

var backgroundFrames = [];
var headFrames = [];
var hairFrames = [];
var faceFrames = [];
var bodyFrames = [];
var legFrames = [];
var handFrames = [];
var name = "";
const createGif = async (_elementData, _tokenId,_setMetaData, _setImg) => {
    backgroundFrames = [];
    headFrames = [];
    hairFrames = [];
    faceFrames = [];
    bodyFrames = [];
    legFrames = [];
    handFrames = [];
    gifFrames = [];

    animation = false;
    for (var j = 0; j < _elementData.length; j++) {
        if (_elementData[j].fileName.includes("gif")) {
            animation = true;
        }
    }

    name = getName(_elementData);
    for (var j = 0; j < _elementData.length; j++) {
        addAttributes(_elementData[j], _tokenId)
        if (_elementData[j].trait_type == "Background") {
            await createFrames(_elementData[j], `./trait-layers/backgrounds`, backgroundFrames);
        }
        if (_elementData[j].trait_type == "head") {
            await createFrames(_elementData[j], `./trait-layers/head`, headFrames);
        }
        if (_elementData[j].trait_type == "hair") {
            await createFrames(_elementData[j], `./trait-layers/hair`, hairFrames);
        }
        if (_elementData[j].trait_type == "face") {
            await createFrames(_elementData[j], `./trait-layers/faces`, faceFrames);
        }
        if (_elementData[j].trait_type == "body") {
            await createFrames(_elementData[j], `./trait-layers/body`, bodyFrames);
        }
        if (_elementData[j].trait_type == "legs") {
            await createFrames(_elementData[j], `./trait-layers/legs`, legFrames);
        }
        if (_elementData[j].trait_type == "hands") {
            await createFrames(_elementData[j], `./trait-layers/hands`, handFrames);
        }
    }
    if (animation) {
        await createImage(_tokenId, framesPerGif,_setMetaData, _setImg);
    } else {
        await createImage(_tokenId, 1,_setMetaData, _setImg);
    }

}

const getName = (_elementData) => {
    var nameChoice = [];
    var name = _elementData[3].fileName.split(".")[0];
    var temp_name = "";
    if (_elementData[5].fileName.includes("gif")) {
        nameChoice.push(_elementData[5].fileName.split(".")[0])
    }
    if (_elementData[6].fileName.includes("gif")) {
        nameChoice.push(_elementData[6].fileName.split(".")[0])
    }

    if (nameChoice.length > 1) {
        if ((Math.floor(Math.random() * 2)) == 0) {
            name = name + " " + nameChoice[0]
        } else {
            name = name + " " + nameChoice[1]
        }
    } else if (nameChoice.length == 1) {
        name = name + " " + nameChoice[0]
    } else {
        name = name + " " + _elementData[1].fileName.split(".")[0]
    }

    if (_elementData[2].fileName.includes("boy")) {
        temp_name = NAMES_LIST["boys"][(Math.floor(Math.random() * NAMES_LIST["boys"].length))]
        name = name + " " + temp_name
    } else {
        temp_name = NAMES_LIST["girls"][(Math.floor(Math.random() * NAMES_LIST["girls"].length))]
        name = name + " " + temp_name
    }

    for (var i = 0; i < blockchain_data[1].length; i++) {
        if (blockchain_data[1][i].includes(temp_name)) {
            return getName(_elementData);
        }
    }
    name = name.charAt(0).toUpperCase() + name.slice(1);
    console.log(name)
    return name;
}


var gifFrames = [];
const createFrames = async (_element, _location, _frameArray) => {
    if (_element.fileName.includes("gif")) {
        console.log(_element.fileName.replace(".gif", ""))
        for(let i = 0; i < framesPerGif; i++){
            console.log("doing frame", i);
            console.log(`${_location}/${_element.fileName.replace(".gif", "")}/frame${i}.png`)
            var tempJimp = await jimp.read(`${_location}/${_element.fileName.replace(".gif", "")}/frame${i+1}.png`).then(img => {return img});
            var tempImg = new jimp(1000,1000);
            tempImg.composite(tempJimp, 0, 0);
            var tempGifFrame = new gifwrap.GifFrame(tempImg.bitmap);
            _frameArray.push(tempGifFrame);
        }
    } else {
        var jimpLayer = await jimp.read(`${_location}/${_element.fileName}`).then(img => { return img });
        for (var i = 0; i < framesPerGif; i++) {
            var tempImg = new jimp(1000, 1000);
            tempImg.composite(jimpLayer, 0, 0);
            var tempGifFrame = new gifwrap.GifFrame(tempImg.bitmap);
            _frameArray.push(tempGifFrame);

        };
    };
}

const createImage = async (_edition, _framesPerGif,_setMetaData, _setImg) => {
    console.log("Working on edition " + _edition + " may take a few seconds.")
    for (var i = 0; i < _framesPerGif; i++) {

        var backgroundJimp = gifwrap.GifUtil.copyAsJimp(jimp, backgroundFrames[i]);
        var headJimp = gifwrap.GifUtil.copyAsJimp(jimp, headFrames[i]);
        var hairJimp = gifwrap.GifUtil.copyAsJimp(jimp, hairFrames[i]);
        var faceJimp = gifwrap.GifUtil.copyAsJimp(jimp, faceFrames[i]);
        var bodyJimp = gifwrap.GifUtil.copyAsJimp(jimp, bodyFrames[i]);
        var handJimp = gifwrap.GifUtil.copyAsJimp(jimp, handFrames[i]);
        var legJimp = gifwrap.GifUtil.copyAsJimp(jimp, legFrames[i]);

        var tempImg = new jimp(1000, 1000);

        tempImg.composite(backgroundJimp, 0, 0);
        tempImg.composite(headJimp, 0, 0);
        tempImg.composite(hairJimp, 0, 0);
        tempImg.composite(faceJimp, 0, 0);
        tempImg.composite(bodyJimp, 0, 0);
        tempImg.composite(handJimp, 0, 0);
        tempImg.composite(legJimp, 0, 0);

        var tempGifFrame = new gifwrap.GifFrame(tempImg.bitmap);
        gifwrap.GifUtil.quantizeDekker(tempGifFrame, 256)
        gifFrames.push(tempGifFrame);
    }
    const codec = new gifwrap.GifCodec();
    await codec.encodeGif(gifFrames).then(gif => {
        imageLocMetadata = "test";
        addMetaData(_edition, imageLocMetadata);
        _setImg(gif.buffer.toString("base64"));
        _setMetaData(metadata);
        console.log("upload data sent")
    })
}

//depreciated as uploading has been offloaded to the transaction page index.jsx file
let promises = [];
let ipfsArray = [];
let imageLocMetadata;
function upload(_encodedGif, _edition,_setMetaData, _setImg) {
    console.log("Uploading ", _edition, " Please wait a little while.")
    promises.push(new Promise((res, rej) => {
        ipfsArray.push({
            path: `images/${_edition}.gif`,
            content: _encodedGif
        })
        res();
    }))

    Promise.all(promises).then(() => {
        axios.post("https://deep-index.moralis.io/api/v2/ipfs/uploadFolder",
            ipfsArray,
            {
                headers: {
                    // "X-API-KEY": 'V03tatFUQEHPHkVBIowET9zQrwlblnPXtxYOYI4NkQw2I1vXIxob3nVo0fr0EFhX',
                    "X-API-KEY": "4m1V9qke89dvNNkMklGL0lY2QHd0FO4EPaRaaXdOFocu0CbG4r5escChjlHGv8Nx",
                    "Content-Type": "application/json",
                    "accept": "application/json"
                }
            }
        ).then((res) => {
            imageLocMetadata = res.data[0].path.replace(/images\/[0-9]+.gif/g, "");
            addMetaData(_edition, imageLocMetadata);
            // fs.readFile("./metadata/_metadataJSTest.json", (err, data) => {
            //     if (err) throw err;
            //     fs.writeFileSync(`./metadata/${_edition}.json`, JSON.stringify(metadata));
            // });
            uploadMetaData(metadata, _edition,_setMetaData, _setImg);
        }).catch((error) => {
            console.log(error)
        })
    })

}
//also depreciated as this function has also been offloaded
let ipfsArrayMetaData = [];
function uploadMetaData(_metadata, _edition,_setMetaData, _setImg) {
    promises.push(new Promise((res, rej) => {
        ipfsArrayMetaData.push({
            path: `metadata/${_edition}.json`,
            content: {
                image: _metadata.imageUrl.replace("https://ipfs.moralis.io:2053/ipfs/", "ipfs://") + "images/" + _edition + ".gif",
                tokenId: _metadata.tokenId,
                name: _metadata.name,
                parent1: _metadata.parent1_id,
                parent2: _metadata.parent2_id,
                attributes: _metadata.attributes
            }
        })
        res();
    }))

    Promise.all(promises).then(() => {
        axios.post("https://deep-index.moralis.io/api/v2/ipfs/uploadFolder",
            ipfsArrayMetaData,
            {
                headers: {
                    // "X-API-KEY": 'V03tatFUQEHPHkVBIowET9zQrwlblnPXtxYOYI4NkQw2I1vXIxob3nVo0fr0EFhX',
                    "X-API-KEY": "4m1V9qke89dvNNkMklGL0lY2QHd0FO4EPaRaaXdOFocu0CbG4r5escChjlHGv8Nx",
                    "Content-Type": "application/json",
                    "accept": "application/json"
                }
            }
        ).then((res) => {
            console.log("\nUse this hash when minting the NFT: " + res.data[0].path.replace(/\/metadata\/[0-9]+.json/g, "").replace("https://ipfs.moralis.io:2053/ipfs/", "") + "\n\nHere is an example link to the IPFS just so you can preview the JSON: " + res.data[0].path);
            _setImg(_metadata.imageUrl + "images/" + _edition + ".gif");
            _setMetaData(res.data[0].path.replace("https://ipfs.moralis.io:2053/ipfs/", ""));
        }).catch((error) => {
            console.log(error)
        })
    })
}

const runCombine = async (t1,t2,tId,setMetaData,setImg) => {
    const token1 = Number(t1);
    const token2 = Number(t2);
    const tokenId = Number(tId);
    console.log("Combining: ", t1, " and ", t2, " to make ", tId)
    promises.push(new Promise((res, rej) => {
        getData().then(data => {
            blockchain_data = data;
            res();
        })
    }))
    Promise.all(promises).then(() => {
        combine(token1, token2, tokenId,setMetaData,setImg);
    })
}
export default runCombine;