import React, { useEffect, useState } from "react";
import { Button, Row, Container } from "react-bootstrap";
import "./index.css";
import HelmetMetaData from "components/HelmetMetaData";

function Account(props) {
    const [pageText, setPageText] = useState('You are not logged in.');
    const [email, setEmail] = useState('');
    const [regDate, setRegDate] = useState('');

    const aws = require("../../helpers/mailses")
    const sendEmail = () => {
        aws.sendContactUsEmail("test");
    }

    function signOut() {
        localStorage.removeItem("jwt");
        window.location.replace("/");
    }

    useEffect(() => {
        console.log(props.userData)
        if (props.userData !== undefined) {
            setEmail(`Email: ${props.userData['data']['user']['user_email']}`)
            setRegDate(`Date of Registration:  ${props.userData['data']['user']['user_registered']}`)
            setPageText(` ${props.userData['data']['user']['user_login']}'s User Profile`)
        }
    }, [props.userData])

    return (
        <div className="account-page">
            <HelmetMetaData
                title="Innov-Edu Certificate | Account"
                description="View the details of your certificate account."
            ></HelmetMetaData>
            <Container className="account-details">
                <h1>{pageText}</h1>
                <hr />
                {props.isLoggedIn ?
                    <div>
                        <Row md={2}>
                            <div>
                                <h2>Registed Date</h2>
                                <p>{regDate}</p>
                            </div>
                            <div>
                                <h2>Email Used</h2>
                                <p>{email}</p>
                            </div>
                        </Row>
                        <Button onClick={() => signOut()}>Sign Out</Button>
                    </div>
                    : <p>If you are logged in please wait for the site to fetch your account details from the innov-edu.ca site.</p>
                }
            </Container>
        </div>
    )
}

export default Account;