import React from "react";
import { Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "./index.css";
import footerLogo from "../../assets/innov-edu-final.png";

function Footer() {
  return (
    <div className="footer">
      <Nav
        className="mr-auto ml-auto nav-fill justify-content-center footer-nav"
        style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
      >
        {/* <Nav.Item className="mt-2 mr-3"></Nav.Item> */}
        <Nav.Item className="mt-2 ml-3 mr-2 ">
          <Link to="/NFTMarketPlace">Marketplace</Link>
        </Nav.Item>
        <Nav.Item className="mt-2 ml-3 mr-3">
          <a href="https://www.innov-edu.ca/">Register for Courses</a>
        </Nav.Item>
        <Nav.Item className="mt-2 ml-3 mr-3">
          <a href="https://loyalty.innov-edu.ca/">Earn Points/Tokens</a>
        </Nav.Item>
        <Nav.Item className="mt-2 ml-3 mr-3">
          <NavLink to="/terms-and-conditions">Terms &amp; Conditions</NavLink>
          {/* <Link to="/terms-and-conditions/">Terms &amp; Conditions</Link> */}
        </Nav.Item>
        <Nav.Item className="mt-2 ml-3 mr-3">
          <NavLink to="/privacy-policy">Privacy Policy</NavLink>
        </Nav.Item>
        <Nav.Item className="mt-2 ml-3 mr-3">
          <a href="https://www.innov-edu.ca/contact-us/">Contact Us</a>
        </Nav.Item>
        {/* <Nav.Item className="mt-2 mr-3">
                    <a href="/sitemap.xml">Sitemap</a>
                </Nav.Item> */}
        {/* <Nav.Item className="mt-2 mr-3"></Nav.Item> */}
      </Nav>
      <p id="copyright">
        Copyright©2021-2022 InnovFin Consulting Inc. All rights reserved
      </p>
      <img
        className="innovfin-logo"
        src={footerLogo}
        alt="InnovFin Edu Foot logo"
      ></img>
    </div>
  );
}

export default Footer;
