import { ContactsOutlined } from "@ant-design/icons";
import { useMoralisDapp } from "contexts/MoralisDappProvider/MoralisDappProvider";
import { useEffect, useState } from "react";
import { useMoralisWeb3Api, useMoralisWeb3ApiCall } from "react-moralis";
import { useIPFS } from "./useIPFS";

export const useNFTTokenIds = (chain, addr) => {
  const { token } = useMoralisWeb3Api();
  const { chainId, marketAddress, nftAddress } = useMoralisDapp();
  const { resolveLink } = useIPFS();
  const [NFTTokenIds, setNFTTokenIds] = useState([]);
  const [totalNFTs, setTotalNFTs] = useState();
  const [fetchSuccess, setFetchSuccess] = useState(true);
  const {
    fetch: getNFTTokenIds,
    data,
    error,
    isLoading,
  } = useMoralisWeb3ApiCall(token.getAllTokenIds, {
    chain: chain,
    address: addr,
    limit: 100,
  });

  useEffect(async () => {
    if (data?.result) {
      // console.log("chainid", chainId);
      // console.log("data", data)
      const NFTs = data.result;
      // console.log(NFTs)
      setTotalNFTs(data.total);
      setFetchSuccess(true);
      for (let NFT of NFTs) {
        
        // console.log("NFT", NFT)
        if (NFT?.metadata) {
          NFT.metadata = JSON.parse(NFT.metadata);
          NFT.image = resolveLink(NFT.metadata?.image);
        } else if (NFT?.token_uri) {
          try {
            var uri = "";
            if(NFT.token_uri.includes("https://ipfs.moralis.io:2053/ipfs/")){
              uri = NFT.token_uri.replace("https://ipfs.moralis.io:2053/ipfs/","https://gateway.moralisipfs.com/ipfs/");
            } else {
              uri = NFT.token_uri;
            }
            // console.log("uri", uri);
            await fetch(uri)
              .then((response) => response.json())
              .then((data) => {
                // console.log("inner data", data)
                NFT.image = resolveLink(data.image);
                NFT.metadata = {attributes: data.attributes, name: data.name, image: data.image};
              });
          } catch (error) {
            setFetchSuccess(false);

            /*          !!Temporary work around to avoid CORS issues when retrieving NFT images!!
            Create a proxy server as per https://dev.to/terieyenike/how-to-create-a-proxy-server-on-heroku-5b5c
            Replace <your url here> with your proxy server_url below
            Remove comments :)

              try {
                await fetch(`<your url here>/${NFT.token_uri}`)
                .then(response => response.json())
                .then(data => {
                  NFT.image = resolveLink(data.image);
                });
              } catch (error) {
                setFetchSuccess(false);
              }

 */
          }
        }
      }

      setNFTTokenIds(NFTs);
    }
  }, [data]);

  return {
    getNFTTokenIds,
    NFTTokenIds,
    totalNFTs,
    fetchSuccess,
    error,
    isLoading,
  };
};
