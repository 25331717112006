import React, { useEffect, useState } from "react";
import { Row, Col, Container, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Card, Image, Tooltip } from "antd";
import Meta from "antd/lib/card/Meta";
import HelmetMetaData from "components/HelmetMetaData";

import "./index.css";

import MissionImage_1 from "../../assets/missions/1.png";
import MissionImage_2 from "../../assets/missions/2.png";
import MissionImage_3 from "../../assets/missions/3.png";
import missionImage from "../../assets/mission-statement.png";
import NFTList from "components/NFT/NFTList";
import { Auth } from "aws-amplify";
import FAQ from "./FAQ";

function Landing(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setLoggedIn(true);
      //get the list of group and store it in a variable
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      //check if the group contains "Cert-Creators"
      if (groups.includes("Members")) {
        //if so, the user is authenticated
        console.log("user", true)
        // setState({ ...state, authUser: true });
      } else {
        console.log("user", false)
        //else, user is not authenticated
        // setState({ ...state, authUser: false });
      }
    } catch (e) {
      console.log('error: ', e)
    }
  }
  useEffect(() => {
    checkUser();
  }, [])
  return (
    <Container>
      {/* {console.log(loggedIn)} */}
      <HelmetMetaData title="Marketplace Home | Innov-Edu"></HelmetMetaData>
      <section className="section">
        <Container>
          <Row className="hero-section">
            <Col lg={7} className="featuredContainer-text">
              <h1 className="hero-section-head">Innov-Edu</h1>
              <h1 className="hero-section-head2">NFT Marketplace</h1>
              <img className="missionImageStyle" src={missionImage} />
              <div className="hero-section-info">
                <h2>Learn and Earn Inshon Non-Fungible Tokens</h2>
                <p className="hero-section-subheading">
                  Our digital asset rewards and encourages individuals to learn
                  about emerging technologies.
                </p>
                <div className="text-center text-lg-start hero-section-button-group">
                  <Link to="/marketplace" className="heroBtn rounded">
                    <span>Explore</span>
                  </Link>
                  <a
                    href="#home_faq"
                    className=" heroBtn rounded"
                    rel="noreferrer"
                  >
                    <span>FAQ</span>
                  </a>
                </div>
              </div>
            </Col>
            <Col className="featuredContainer">
              <video
                className="intro_video"
                poster="./cover.png"
                title="Video"
                width="100%"
                controls
                controlsList="nodownload"
                src="https://innovfin-videos.s3.us-east-2.amazonaws.com/marketplace-intro.mp4"
                autoPlay
              >
                {/* <source src="./marketplace-intro.mp4" type="video/mp4" /> */}
                {/* <source src="./marketplace-intro.ogg" type="video/ogg" /> */}
              </video>
              <p className="featuredImageCaption">
                "Augmenting the Global Human Capital, So No One is Left Behind
                by Innovation"
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <main>
        <section id="currentCollections" className="section">
          <Container>
            <div className="missionStatementContainer">
              <div className="missionBox">
                <h2>LEARN AND EARN NFT REWARDS</h2>
                <div className="missionItems">
                  <div className="mission">
                    <div className="icon_image_box">
                      <img
                        src={MissionImage_1}
                        alt="WHAT ARE INSHON NFTS?"
                        className="icon_image"
                      />
                    </div>
                    <p className="title">WHAT ARE INSHON NFTS?</p>
                    <p className="missionContent">
                      <a
                        className="missionLink"
                        href="https://www.innov-edu.ca/inshon-nfts/"
                      >
                        Inshons
                      </a>{" "}
                      are what we call our hero NFTs. They are rewarded to
                      learners who complete a certain number, or level of{" "}
                      <a
                        className="missionLink"
                        href="https://www.innov-edu.ca/shop/"
                      >
                        courses
                      </a>
                      .
                    </p>
                  </div>
                  <div className="mission">
                    <div className="icon_image_box">
                      <img src={MissionImage_2} alt="" className="icon_image" />
                    </div>
                    <p className="title">WHAT ARE NFTS?</p>
                    <p className="missionContent">
                      <a
                        className="missionLink"
                        href="https://www.innov-edu.ca/inshon-nfts/"
                      >
                        NFTs*
                      </a>{" "}
                      or Non-Fungibles Tokens are unique digital collectibles
                      that are by definition unique, tradeable and traceable on
                      the{" "}
                      <a
                        className="missionLink"
                        href="https://www.innov-edu.ca/category/blog/"
                      >
                        Blockchain
                      </a>
                      .
                    </p>
                  </div>
                  <div className="mission">
                    <div className="icon_image_box">
                      <img src={MissionImage_3} alt="" className="icon_image" />
                    </div>
                    <p className="title">HOW CAN YOU EARN INSHON NFTS?</p>
                    <p className="missionContent">
                      There are three ways to earn{" "}
                      <a
                        className="missionLink"
                        href="https://www.innov-edu.ca/inshon-nfts/"
                      >
                        Inshon NFTs
                      </a>
                      . <br />
                      1. Join and participate in ongoing contests we host! To be
                      notified, join our{" "}
                      <a
                        className="missionLink"
                        href="https://discord.com/invite/xhEKNKvnV5"
                      >
                        Discord community
                      </a>
                      . 2. Register on{" "}
                      <a
                        className="missionLink"
                        href="https://www.innov-edu.ca/homepage/"
                      >
                        Innov-Edu
                      </a>{" "}
                      for the monthly or yearly subscription. 3. Purchase on
                      <a
                        className="missionLink"
                        href="https://www.marketplace.innov-edu.ca/"
                      >
                        Innov-Edu marketplace
                      </a>{" "}
                      for over hundreds of personalities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Row className="mt-5">
              <Col md={12} className="mb-4">
                <div className="d-flex flex-column ">
                  <h3>Our Collections</h3>
                  <p>Below are our latest NFT Collections.</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="d-flex flex-column  mb-4 ">
                  <h5>Hangman Collection</h5>
                  <p className="my-0 text-muted italic">
                    This collection is only available on the Mumbai Testnet.
                  </p>
                  <p style={{ width: "50rem" }}>
                    The Hangman collection includes practice (not real) NFTs
                    that we are providing for free to our community so everyone
                    can practice their NFT acquisition, exchange and trading
                    skills risk free.
                  </p>
                </div>
                <NFTList limit={4} isLoggedIn={props.isLoggedIn} address={"0x573Cb7d5064798B05A4dF809Bea630101A48eE5b"} chain={"0x13881"} />
              </Col>
              <div className="mb-5">
                <div className="d-flex flex-column NFTs   mb-4">
                  <h5>Inshon Collection</h5>
                  <p className="my-0 text-muted italic">
                    This collection will be available March 31st, 2022.
                  </p>
                  <p>
                    Inshons are real NFTs rewarded to users who take and
                    complete courses on{" "}
                    <a href="www.innov-edu.ca" target="_blank">
                      www.innov-edu.ca.{" "}
                    </a>
                    These Inshons, coined as “hero” in Chinese, are there to
                    accompany learners to become innovation hero at the end of
                    their upskilling and reskilling journey on the Innov-Edu
                    platform.
                  </p>
                </div>
                <div
                  // changed class so that it would match the other section
                  // className="home-NFTs"
                  className="nft-list"
                >
                  {[1, 2, 3, 4].map((_, idx) => {
                    return (
                      <Card
                        hoverable
                        style={{
                          width: 240,
                          border: "2px solid #e7eaf3",
                          marginRight: "10px",
                        }}
                        cover={
                          <Image
                            preview={false}
                            src={
                              _ !== 3
                                ? `topsecret/insh-${_}.png`
                                : `topsecret/insh-${_}.gif`
                            }
                            width="auto"
                            alt=""
                            style={{ height: "240px" }}
                          />
                        }
                        key={idx}
                      >
                        <Meta title={`Inshon #${_}`} />
                      </Card>
                    );
                  })}
                  <div>
                    <a
                      className="joinBtn viewCollectionBtn"
                      href="https://www.innov-edu.ca/shop/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Access Courses
                    </a>
                  </div>
                </div>
              </div>
            </Row>
            <div className="my-5 setupSection">
              <div className="setupSectionContent">
                <div className="setupSectionDescription">
                  <h2>MetaMask Tutorial </h2>
                  <p>
                    Welcome to the Metamask tutorial! By watching this tutorial,
                    you will be able to connect Metamask to the Mumbai test
                    network in order to start trading and collecting NFTs in the
                    Marketplace.
                  </p>
                </div>
                <div>
                  <video
                    width="560"
                    height="315"
                    src="https://innovfin-videos.s3.us-east-2.amazonaws.com/Marketplace+Metamask+tutorial+V2.mp4"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    controls="controls"
                    controlsList="nodownload"
                    className="intro_video"
                  ></video>
                </div>
              </div>
            </div>

            <div className="my-5 setupSection">
              <div className="setupSectionContent">
                <div className="setupSectionDescription">
                  <h2>How to buy Hangman NFTs </h2>
                  <p>
                    This tutorial will guide you through buying your very own Hangman NFT! Please Ensure you have Metamask installed, and are connected to the Mumbai Network.
                  </p>
                </div>
                <div>
                  <video
                    width="560"
                    height="315"
                    src="https://innovfin-videos.s3.us-east-2.amazonaws.com/BuyHangman.mp4"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="intro_video"
                    controls="controls"
                    controlsList="nodownload"
                    poster="./buyMumbai.png"
                  ></video>
                </div>
              </div>
            </div>

            <div className="my-5 setupSection">
              <div className="setupSectionContent">
                <div className="setupSectionDescription">
                  <h2>How to buy Inshon NFTs </h2>
                  <p>
                    This tutorial will guide you through buying your very own Inshon NFT! Please ensure you have Metamask installed, and are connected to the Polygon Network.
                  </p>
                </div>
                <div>
                  <video
                    width="560"
                    height="315"
                    src="https://innovfin-videos.s3.us-east-2.amazonaws.com/BuyInshon.mp4"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="intro_video"
                    controls="controls"
                    controlsList="nodownload"
                    poster="./buyPolygon.png"
                  ></video>
                </div>
              </div>
            </div>

            <div className="my-5 setupSection">
              <div className="setupSectionContent">
                <div className="setupSectionDescription">
                  <h2>How to add IEDU Tokens to Mumbai</h2>
                  <p>
                    Watch this tutorial to find out how to add and display your IEDU Tokens on your Metamask wallet on Mumbai Testnet.
                    Copy &amp; Paste Token Address: 0xbAf8Ed1fc38af64575BEaC7213b9FE8B2b01d201
                  </p>
                  <p style={{ fontWeight: 'bold' }}>
                    Copy &amp; Paste Token Address: 0xbd5E96a0212f5831f35853765c73c2B84f8E9B70
                  </p>
                </div>
                <div>
                  <video
                    width="560"
                    height="315"
                    src="https://innovfin-videos.s3.us-east-2.amazonaws.com/AddIEDUPolygon.mp4"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="intro_video"
                    controls="controls"
                    controlsList="nodownload"
                    poster="./addMumbai.png"
                  ></video>
                </div>
              </div>
            </div>

            <div className="my-5 setupSection">
              <div className="setupSectionContent">
                <div className="setupSectionDescription">
                  <h2>How to add IEDU Tokens to Polygon </h2>
                  <p>
                    Watch this tutorial to find out how to add and display your IEDU Tokens on your Metamask wallet on Polygon livenet.
                    Copy &amp; Paste Token Address: 0xbAf8Ed1fc38af64575BEaC7213b9FE8B2b01d201
                  </p>
                  <p style={{ fontWeight: 'bold' }}>
                    Copy &amp; Paste Token Address: 0xbAf8Ed1fc38af64575BEaC7213b9FE8B2b01d201
                  </p>
                </div>
                <div>
                  <video
                    width="560"
                    height="315"
                    src="https://innovfin-videos.s3.us-east-2.amazonaws.com/AddIEDUPolygon.mp4"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="intro_video"
                    controls="controls"
                    controlsList="nodownload"
                    poster="./addPolygon.png"
                  ></video>
                </div>
              </div>
            </div>

            {/* <div className="blogPosts">
              <h2>Blog Posts</h2>
              <div className="blogPostsContainer">
                <div className="blogItem">
                  <div className="blogImageContainer">
                    <img src="https://opensea.io/blog/wp-content/uploads/2022/02/image-13.png" />
                  </div>
                  <div className="blogContentContainer">
                    <p>Blog item</p>
                  </div>
                </div>
              </div>
            </div> */}

            <Row className="mb-5">
              <Col md={4}>
                <img
                  src="https://www.innov-edu.ca/wp-content/uploads/2022/02/Social-Media-Like-Icons.H03.2k-1024x1024.png"
                  className="img-fluid my-auto"
                  width="516"
                />
              </Col>
              <Col md={8}>
                <h3 id="">
                  <strong>Join our Discord community</strong>
                </h3>

                <p>
                  By joining the Discord community, you will be part of a group
                  of dedicated individuals committed to upskilling and
                  reskilling themselves as lifelong student of the innovative
                  technologies that are reshaping our world.
                </p>

                <p>
                  By joining the Discord community, you will also have the
                  opportunity to ask your most burning questions by directly
                  interacting with the team behind Innov-Edu and the Inshon
                  NFTs.
                </p>

                <p className="mb-5">
                  Lastly, by joining the Discord community, you will have the
                  opportunity to be the first informed of progress and updates
                  in our development journey.
                </p>

                <a
                  href="https://discord.com/invite/fYC7bKxbr6"
                  className="my-3 rounded joinBtn"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span>Join Community</span>
                </a>
              </Col>
            </Row>

            <h2 id="home_faq">
              FAQ
            </h2>
            <Accordion>
              {FAQ["questions"].map((data, i) => {
                return (
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header>{data}</Accordion.Header>
                    <Accordion.Body>
                      {FAQ["answers"][i]}
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}

            </Accordion>
          </Container>
        </section>
      </main>
    </Container>
  );
}

export default Landing;
