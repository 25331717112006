import { useEffect, useState } from "react";

import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import { useMoralisDapp } from "./contexts/MoralisDappProvider/MoralisDappProvider";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

import logoIcon from "assets/InnovEdu Logo Final.png";

import Footer from "./components/Footer";
import PrivacyPolicy from "components/PrivacyPolicy";
import TermsAndCondition from "components/TermsAndConditions";

import ExplorePage from "./containers/ExplorePage";
import LandingPage from "./containers/LandingPage";
import TransactionPage from "./containers/TransactionsPage";
import CollectionPage from "./containers/CollectionPage";

import Navigation from "components/Navigation";
import DetailedPage from "containers/DetailedPage";
import NFTPage from "containers/NFTPage";

import IEDUBalance from "components/IEDUBalance";
import Login from "./containers/AccountPages/login";
import { Redirect } from "react-router-dom";
import Signup from "containers/AccountPages/signup";
import SignupConfirm from "containers/AccountPages/SignupConfirm";
import ForgotPassword from "containers/AccountPages/ForgotPassword";
import ForgotPasswordConfirm from "containers/AccountPages/ForgotPasswordConfirm";
import Account from "containers/AccountPages/Account";
import LoginWordPress from "containers/AccountPages/LoginWordPress";

const App = ({ isServerInfo }) => {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
    useMoralis();

  const { walletAddress, marketAddress, ieduABI, ieduAddress, nftAddress, chainId } =
    useMoralisDapp();

    // console.log(chainId);
  const [username, setUsername] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userData, setUserData] = useState();

  useEffect(() => {
    let userJWT = localStorage.getItem('jwt') || '';
    console.log(userJWT);
    if (userJWT.length > 0) {
      let url = `https://www.innov-edu.ca/?rest_route=/simple-jwt-login/v1/auth/validate&JWT=${userJWT}`;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          if (data['success'] === true) {
            console.log("user is persisted")
            setUserData(data)
            setIsLoggedIn(true)
            setUsername(data['data']['user']['user_login'])
          } else {
            console.log(data, "persist failed")
          }
        })
    }
  }, []);
  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading && isLoggedIn) enableWeb3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  return (
    <div style={{ height: "100vh", overflow: "auto", background: "white" }}>
      {/* {console.log("data", userData)} */}
      {/* {console.log("web3, ", isWeb3Enabled)} */}
      <Router>
        <Navigation isLoggedIn={isLoggedIn} walletAddress={walletAddress} />
        <div className="home-content">
          <Switch>
            <Route path="/login">
              <LoginWordPress isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} username={username} />
            </Route>
            {/* <Route path="/login">
              <Login />
            </Route> */}
            <Route path="/signup">
              <Signup />
            </Route>
            <Route path="/signup-confirm">
              <SignupConfirm />
            </Route>
            <Route path="/reset">
              <ForgotPassword />
            </Route>
            <Route path="/confirm-reset">
              <ForgotPasswordConfirm />
            </Route>
            <Route path="/account">
              <Account isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} username={username} userData={userData} />
            </Route>
            <Route path="/collection">
              <CollectionPage />
            </Route>
            <Route path="/marketplace">
              <ExplorePage isLoggedIn={isLoggedIn} />
            </Route>
            <Route path="/Transactions">
              <TransactionPage />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/terms-and-conditions">
              <TermsAndCondition />
            </Route>
            <Route exact path="/:nftName">
              <NFTPage address={nftAddress} isLoggedIn={isLoggedIn} chain={chainId} />
            </Route>
            <Route path="/:nftName/:id">
              <DetailedPage />
            </Route>
            <Route path="/">
              <LandingPage isLoggedIn={isLoggedIn} />
            </Route>
          </Switch>
        </div>

        <Footer />
      </Router>
    </div>
  );
};

export default App;
