import React, { useEffect, useState } from "react";
import { useMoralis, useMoralisQuery } from "react-moralis";
import {
  Card,
  Image,
  Tooltip,
  Modal,
  Input,
  Alert,
  Spin,
  Button,
  notification,
  Tag,
} from "antd";
import { useNFTBalance } from "hooks/useNFTBalance";
import { FileSearchOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { useMoralisDapp } from "contexts/MoralisDappProvider/MoralisDappProvider";
import { getExplorer } from "helpers/networks";
import { useWeb3ExecuteFunction } from "react-moralis";
import { Container } from "react-bootstrap";
import HelmetMetaData from "components/HelmetMetaData";
import { useHistory, useParams } from "react-router-dom";

import "./index.css";
import { Link } from "react-router-dom";
import { useNFTTokenIds } from "hooks/useNFTTokenIds";
import { getCollectionChainAndName } from "helpers/collections";

function DetailedPage() {
  const params = useParams();
  const [marketItem, setMarketItem] = useState(null);
  const [nft, setNft] = useState(null);
  const [nftToBuy, setNftToBuy] = useState(null);
  const [loading, setLoading] = useState(false);
  const { Moralis } = useMoralis();
  const { chainId, marketAddress, nftAddress, contractABI, walletAddress, ieduAddress } = useMoralisDapp();
  let collectionData = [{}];
  if(params.nftName === "Hangman Collection"){
    collectionData = getCollectionChainAndName("0x13881", params.nftName);
  } else if (params.nftName === "Inshon"){
    collectionData = getCollectionChainAndName("0x89", params.nftName);
  }
  const { NFTTokenIds, totalNFTs, fetchSuccess } = useNFTTokenIds(
    chainId, collectionData[0].addrs
  );
  const queryMarketItems = useMoralisQuery("CreatedMarketItems", (query) =>
    query.limit(1000)
  );

  const purchaseItemFunction = "createMarketSaleERC20";
  const contractABIJson = JSON.parse(contractABI);
  const contractProcessor = useWeb3ExecuteFunction();

  const fetchMarketItems = JSON.parse(
    JSON.stringify(queryMarketItems.data, [
      "objectId",
      "createdAt",
      "price",
      "nftContract",
      "itemId",
      "sold",
      "tokenId",
      "seller",
      "owner",
      "confirmed",
    ])
  );
  const getMarketItem = (nft) => {
    const result = fetchMarketItems?.find(
      (e) =>
        e.nftContract === nft?.token_address &&
        e.tokenId === nft?.token_id &&
        e.sold === false &&
        e.confirmed === true
    );
    return result;
  };
  useEffect(() => {
    setNft(
      NFTTokenIds.filter((d) => {
        return d.token_id === params.id;
      })[0]
    );
  }, [NFTTokenIds, collectionData, params.id]);

  useEffect(() => {
    setMarketItem(getMarketItem(nft));
  }, [nft]);
  useEffect(() => {
    console.log(marketItem);
  }, [marketItem]);
  useEffect(()=>{
    console.log(chainId)
  },[chainId])
  const handleBuyClick = (nft) => {
    console.log("Buy clicked");
    console.log(getMarketItem(nft));
    setNftToBuy(nft);
    purchase(nft);
    // setVisibility(true);
  };
  async function purchase(nft) {
    setLoading(true);
    
    const tokenDetails = getMarketItem(nft);
    console.log("token", tokenDetails, "nfttobuy", nft,"market ", marketAddress, "iedu", ieduAddress, "abi", contractABIJson)
    const itemID = tokenDetails.itemId;
    const tokenPrice = tokenDetails.price;
    const ops = {
      contractAddress: marketAddress,
      functionName: purchaseItemFunction,
      abi: contractABIJson,
      params: {
        nftContract: nft.token_address,
        itemId: parseInt(itemID),
        token: ieduAddress,
      },
      // msgValue: tokenPrice,
    };

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        setLoading(false);
        // setVisibility(false);
        updateSoldMarketItem();
        succPurchase();
      },
      onError: (error) => {
        setLoading(false);
        failPurchase();
      },
    });
  }

  async function updateSoldMarketItem() {
    const id = getMarketItem(nftToBuy).objectId;
    const marketList = Moralis.Object.extend("CreatedMarketItems");
    const query = new Moralis.Query(marketList);
    await query.get(id).then((obj) => {
      obj.set("sold", true);
      obj.set("owner", walletAddress);
      obj.save();
    });
  }

  function succPurchase() {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: "Success!",
      content: `You have purchased this NFT`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function failPurchase() {
    let secondsToGo = 5;
    const modal = Modal.error({
      title: "Error!",
      content: `There was a problem when purchasing this NFT`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  return (
    <div>
      <HelmetMetaData
        title="Marketplace - Collection | Innov-Edu"
        description="Check on the Innov-Edu NFTs that you own on this page."
      ></HelmetMetaData>

      {nft && (
        <div className="pageContainer">
          {/* {console.log(nft)} */}
          <div className="pageWrapper">
            <div className="nftSummary">
              <div className="mediaContent">
                <div className="mediaWrapper">
                  <img src={nft?.image} />
                </div>
              </div>
              <Spin spinning={loading}>
              {marketItem && (
                <div className="nftMain-item">
                  {marketItem && <Tag color="success">Up for Sale</Tag>}
                  <p className="nftMain-item-title">Current price</p>
                  <div className="priceSection">
                    {marketItem && (
                      <div className="priceWrapper">
                        {marketItem.price} IEDU
                      </div>
                    )}
                    {/* <div className="fiatWrapper">(0.15 MATIC)</div> */}
                  </div>
                  
                  <div className="buttonGroup">
                    <button
                      disabled={!getMarketItem(nft)}
                      onClick={()=>handleBuyClick(nft)}
                    >
                      Buy
                    </button>
                  </div>
                </div>
              )}
              </Spin>
            </div>
            <div className="nftMain">
              <div className="nftMainHeader">
                <div className="nftCollectionLead">
                  <Link className="" to={`/${nft?.name}`}>
                    {nft.name}
                  </Link>
                </div>
                <h1 className="title">{nft.metadata.name}</h1>
              </div>
              <div className="extraContent">
                {/* <div className="descriptionContent">
                  <p className="title">Description</p>
                  <p>This {nft.name} is called </p>
                </div> */}

                <div className="aboutContent">
                  <p className="title">About {nft.name}</p>
                  <p>{collectionData[0].description}</p>
                </div>
                <hr />
                <div className="detailsContent">
                  <p className="title">Details</p>
                  <div className="detailsFlex">
                    <div className="detailsFlex-item">
                      <p>Contract Address</p>
                      <p>{nft?.token_address.replace(/(.{12})..+/, "$1...")}</p>
                    </div>
                    <div className="detailsFlex-item">
                      <p>Token ID</p>
                      <p>{nft.token_id}</p>
                    </div>
                    <div className="detailsFlex-item">
                      <p>Token Standard</p>
                      <p>{nft.contract_type}</p>
                    </div>
                    <div className="detailsFlex-item">
                      <p>Symbol</p>
                      <p>{nft.symbol}</p>
                    </div>
                    <hr />
                    <div className="detailsFlex-item">
                      <p className="title">Metadata</p>
                      <div>
                        {nft.metadata.attributes.map((e) => {
                          return (
                            <p>
                              {e.trait_type} - {e.name ? e.name : e.value}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DetailedPage;
