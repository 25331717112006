import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

import Account from "components/Account";
import Chains from "components/Chains";

import SearchCollections from "components/SearchCollections";

import NativeBalance from "components/NativeBalance";
import { Button, Container, Modal, Nav, Navbar } from "react-bootstrap";
import IEDUBalance from "components/IEDUBalance";

import logoIcon from "assets/InnovEdu Logo Final.png";

export const Logo = () => (
  <div style={{ display: "flex" }}>
    <img src={logoIcon} width="150" height="auto" alt="InnovFin Logo" />
  </div>
);

function Navigation({ setInputValue, walletAddress, isLoggedIn }) {
  return (
    <Navbar collapseOnSelect expand="sm">
      <Container>
        <NavLink to="/">
          <Logo />
        </NavLink>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="responsive-navbar-nav-toggle"
        />
        <SearchCollections setInputValue={setInputValue} />
        <Navbar.Collapse
          className="justify-content-space-evenly"
          id="responsive-navbar-nav"
        >
          <Nav>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/marketplace">Explore</Nav.Link>
            {isLoggedIn ? <>
              {walletAddress && (
                <Nav.Link href="/collection">Collection</Nav.Link>
              )}
              {walletAddress && (
                <Nav.Link href="/Transactions">Transactions</Nav.Link>
              )} </> : null}

          </Nav>
        </Navbar.Collapse>

        {isLoggedIn ?
          <Navbar.Collapse
            className="justify-content-end"
            id="responsive-navbar-nav"
          >
            {/* <Nav> */}
            <IEDUBalance />
            <Chains />
            {walletAddress && <NativeBalance />}
            <Account />
            {/* </Nav> */}
            <Button className="nav-btn" href="/account">Account</Button>
          </Navbar.Collapse>
          : <Button className="nav-btn" href="/login">Login</Button>}

      </Container>
    </Navbar>
  );
}

export default Navigation;
