import Modal from "antd/lib/modal/Modal";
import { useMoralisDapp } from "contexts/MoralisDappProvider/MoralisDappProvider";
import { getCollectionsByChain } from "helpers/collections";
import { getNativeByChain } from "helpers/networks";
import { useNFTTokenIds } from "hooks/useNFTTokenIds";
import React, { useState } from "react";
import {
  useMoralis,
  useMoralisQuery,
  useWeb3ExecuteFunction,
} from "react-moralis";

import CollectionItem from "./CollectionItem";

function CollectionList({ limit, isLoggedIn }) {
  const { NFTTokenIds, totalNFTs, fetchSuccess } = useNFTTokenIds(
    "0x573Cb7d5064798B05A4dF809Bea630101A48eE5b"
  );
  const [visible, setVisibility] = useState(false);
  const [nftToBuy, setNftToBuy] = useState(null);
  const [loading, setLoading] = useState(false);
  const contractProcessor = useWeb3ExecuteFunction();
  const { chainId, marketAddress, contractABI, walletAddress } =
    useMoralisDapp();
  const nativeName = getNativeByChain(chainId);
  const contractABIJson = JSON.parse(contractABI);
  const { Moralis } = useMoralis();
  const queryMarketItems = useMoralisQuery("CreatedMarketItems", (query) =>
    query.limit(1000)
  );
  const fetchMarketItems = JSON.parse(
    JSON.stringify(queryMarketItems.data, [
      "objectId",
      "createdAt",
      "price",
      "nftContract",
      "itemId",
      "sold",
      "tokenId",
      "seller",
      "owner",
      "confirmed",
    ])
  );
  const purchaseItemFunction = "createMarketSaleERC20";

  let NFTCollections;
  if (chainId === undefined) {
    console.log("chainId NULL");
    NFTCollections = getCollectionsByChain("0x13881");
  } else if(chainId === "0x13881" || chainId === "0x89"){
    NFTCollections = getCollectionsByChain(chainId);
  } else {
    NFTCollections = getCollectionsByChain("0x13881");
  }

  async function purchase() {
    setLoading(true);
    const tokenDetails = getMarketItem(nftToBuy);
    const itemID = tokenDetails.itemId;
    const tokenPrice = tokenDetails.price;
    const ops = {
      contractAddress: marketAddress,
      functionName: purchaseItemFunction,
      abi: contractABIJson,
      params: {
        nftContract: nftToBuy.token_address,
        itemId: itemID,
        token: "0xbd5E96a0212f5831f35853765c73c2B84f8E9B70",
      },
      // msgValue: tokenPrice,
    };

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        setLoading(false);
        setVisibility(false);
        updateSoldMarketItem();
        succPurchase();
      },
      onError: (error) => {
        setLoading(false);
        failPurchase();
      },
    });
  }

  const handleBuyClick = (nft) => {
    setNftToBuy(nft);
    setVisibility(true);
  };

  function succPurchase() {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: "Success!",
      content: `You have purchased this NFT`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function failPurchase() {
    let secondsToGo = 5;
    const modal = Modal.error({
      title: "Error!",
      content: `There was a problem when purchasing this NFT`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  async function updateSoldMarketItem() {
    const id = getMarketItem(nftToBuy).objectId;
    const marketList = Moralis.Object.extend("CreatedMarketItems");
    const query = new Moralis.Query(marketList);
    await query.get(id).then((obj) => {
      obj.set("sold", true);
      obj.set("owner", walletAddress);
      obj.save();
    });
  }

  const getMarketItem = (nft) => {
    const result = fetchMarketItems?.find(
      (e) =>
        e.nftContract === nft?.token_address &&
        e.tokenId === nft?.token_id &&
        e.sold === false &&
        e.confirmed === true
    );
    return result;
  };
  const onClose = (e) => {
    console.log(e, "I was closed.");
  };

  const styles = {
    NFTs: {
      display: "flex",
      flexWrap: "wrap",
      WebkitBoxPack: "start",
      justifyContent: "center",
      margin: "0 auto",
      maxWidth: "1000px",
      gap: "10px",
    },
  };

  return NFTCollections?.slice(0, limit ? limit : NFTCollections.length).map(
    (nft, index) => {
      return (
        <div style={styles.NFTs}>
          <CollectionItem nft={nft} key={index} isLoggedIn={isLoggedIn} />
        </div>
      );
    }
  );
}

export default CollectionList;
