import React from "react";

import { Card } from "antd";

import { Container } from "react-bootstrap";
import HelmetMetaData from "components/HelmetMetaData";
import CollectionList from "components/Collections/CollectionList";

function NFTTokenIds({ inputValue, setInputValue, limit, isLoggedIn }) {

  return (
    <>
      <HelmetMetaData
        title="Marketplace - Explore | Innov-Edu"
        description="Explore the NFTs that we have available for exchange. You could see which NFTs people are planning on exchanging here."
      ></HelmetMetaData>
      <Container className="market-section">
        <h1>Explore Collections</h1>
        <div className="home-NFTs">
          <CollectionList isLoggedIn={isLoggedIn} />
        </div>
      </Container>
    </>
  );
}

export default NFTTokenIds;
