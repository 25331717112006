
import { useMoralisDapp } from "contexts/MoralisDappProvider/MoralisDappProvider";
import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import { getEllipsisTxt } from "helpers/formatters";
import Blockie from "./Blockie";
import { Button, Card, Input, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import Address from "./Address/Address";
import { SelectOutlined } from "@ant-design/icons";
import { getExplorer } from "helpers/networks";
const styles = {
    account: {
        height: "42px",
        padding: "0.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        borderRadius: "12px",
        cursor: "pointer",
        textAlign: "center",
    },
    text: {
        color: "#0069d3",
        fontWeight: "500",
    },
};

function IEDUBalance() {
    const { authenticate, isAuthenticated, logout, enableWeb3, isWeb3EnableLoading, isWeb3Enabled } = useMoralis();
    const { walletAddress, chainId, marketAddress, ieduABI, ieduAddress } = useMoralisDapp();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [importCheck, setImportCheck] = useState(true);
    const [userIEDUBalance, setUserIEDUBalance] = useState(-1);

    const ieduABIJson = JSON.parse(ieduABI);
    const [ieduBalance, setIEDUBalance] = useState();
    const contractProcessor = useWeb3ExecuteFunction();

    const [price, setPrice] = useState("0");

    async function importIEDU(importPrice) {
        setLoading(true);
        const ops = {
            contractAddress: ieduAddress,
            functionName: "increaseAllowance",
            abi: ieduABIJson,
            params: {
                spender: marketAddress,
                addedValue: importPrice
            },
        };

        await contractProcessor.fetch({
            params: ops,
            onSuccess: () => {
                console.log("success");
                setLoading(false);
                setIsModalVisible(false);
            },
            onError: (error) => {
                setLoading(false);
                console.log("Unable")
            },
        });
    }

    useEffect(() => {
        if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) enableWeb3();

        const ops = {
            contractAddress: ieduAddress,
            functionName: "allowance",
            abi: ieduABIJson,
            params: {
                owner: walletAddress,
                spender: marketAddress
            },
        };

        contractProcessor.fetch({
            params: ops,
            onSuccess: (e) => {
                console.log("success", e);
                setIEDUBalance(e);
            },
            onError: (error) => {
                console.log("Not connected")
            }
        })
        const ops2 = {
            contractAddress: ieduAddress,
            functionName: "balanceOf",
            abi: ieduABIJson,
            params: {
                account: walletAddress,
            },
        };

        contractProcessor.fetch({
            params: ops2,
            onSuccess: (e) => {
                console.log("success", e);
                setUserIEDUBalance(e);
            },
            onError: (error) => {
                console.log("Not connected")
            }
        })
    }, [isAuthenticated, isWeb3Enabled])

    useEffect(() => {
        console.log(userIEDUBalance);
        if (price === "0") {
            setImportCheck(true);
        } else if (parseInt(price) > parseInt(userIEDUBalance) - ieduBalance ) {
            setImportCheck(true);
        } else {
            setImportCheck(false);
        }
    }, [price])

    if (!isAuthenticated) {
        return (
            <div>
            </div>
        );
    }

    return (
        <>
            <div style={styles.account} onClick={() => setIsModalVisible(true)}>
                <p style={styles.text}>{ieduBalance} IEDU Imported</p>
            </div>
            <Modal
                title="IEDU Marketplace Balance"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => importIEDU(price)}
                okText="import"
                footer={[
                    <Button key={1} onClick={() => setIsModalVisible(false)}>Cancel</Button>,
                    <Button key={2} disabled={importCheck} onClick={() => importIEDU(price)} type="primary">
                        Import
                    </Button>
                ]}>
                <Spin spinning={loading}>
                    <h4>
                        What does this number represent?
                    </h4>
                    <p>
                        This number shows the number of IEDU tokens that you have transferred from your Metamask wallet to the marketplace, to enable you to start buying and selling NFTs. Think about this balance as an amount you add from your credit card (i.e. the wallet) to your Starbucks card (i.e. the marketplace) to start buying Starbucks coffee.
                        <br /><br />
                        If you already have received IEDU tokens in your wallet, click on the import button to add those tokens to the marketplace. Please note that you cannot upload more tokens than you have in your wallet, just like you can not spend more money than you have on your credit card for your Starbucks coffee.
                    </p>
                    <br />
                    <h4>
                        Why is my IEDU balance 0 even though I have recieved some IEDU from the loyalty platform?
                    </h4>
                    <p>
                        For now, the Loyalty Platform and this NFT Marketplace do not communicate with each other. Therefore, any amounts earned on the Loyalty platform do not automatically transfer to the marketplace. We are working to enable a more seamless process in the foreseeable future.
                        <br /><br />
                        To load up your wallet with IEDU tokens, please email: info@innovfin.ca and a member of our team will soon get in touch with you with instructions.
                    </p>
                    <br />
                    <h4>
                        How do I import my IEDU tokens?
                    </h4>
                    <p>
                        Now that your wallet has some IEDU tokens, please enter the amount you want to import from your wallet into the marketplace, ensuring that this amount does not go over your IEDU token balance.
                        <br /><br />
                        <b>Important Note</b>: Importing IEDU tokens will cost a very small transaction fee commonly called “gas fee” paid in another token called MATIC. Please follow our video tutorial on the Home Page for steps on how to add the MATIC tokens to your wallet for “Free”.
                    </p>
                    <br />
                    <Input
                        autoFocus
                        placeholder="Amount of IEDU you want to import."
                        onChange={(e) => setPrice(e.target.value)}
                    />
                    <p style={{ fontSize: "8pt" }}>
                        {userIEDUBalance !== -1 ?
                            <>Please note that you can only import IEDU tokens equal to or less than your actual IEDU balance: {parseInt(userIEDUBalance) - ieduBalance}</>
                            : null}
                    </p>
                </Spin>
            </Modal>
        </>
    );
}

export default IEDUBalance;
